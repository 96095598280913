@font-face {
    font-family: "MontserratReg";
    src: url("../Fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "MontserratSB";
    src: url("../Fonts/Montserrat-SemiBold.ttf") format("truetype");
}

body{
    margin: 0 !important;
    padding: 0 !important;
}

