.roomChoice{
    padding: 50px 0px;
    background-color: #fff9f2;
}

.roomChoice Button{
    color: black;
    font-weight: 600;
    font-size: 23px;
}

.roomChoice Button:hover{
    font-size: 30px;
}

.normalChoice{
    padding: 40px 0px;
}

.roomDetail{
    padding: 45px 100px 10px 100px;
}

.roomDetail h1{
    letter-spacing: 0.05em;
}

.roomDetail p{
    width: 75%;
    padding-top:20px;
    margin: auto;
    font-size: 20px;
    line-height: 40px;
}

.roomlogolist{
    display: flex;
    width: 65%;
}

.mainRoomImage{
    padding-top: 30px;
}

.mainRoomImage img{
    box-shadow: 13px 13px 30px#DCDCDC;
}

.roomImage{
    padding: 20px 0px;
}

.roomfac h3{
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .roomChoice{
        padding: 30px 0px 30px 0px;
    }

    .roomChoice Button{
        font-weight: 600;
        font-size: 16px;
    }

    .roomChoice Button:hover{
        font-size: 16px;
        text-decoration: underline;
    }

    .normalChoice{
        padding: 20px 0px 20px 0px;
    }

    .roomDetail{
        padding: 45px 10px 10px 10px;
    }
    
    .roomDetail p{
        width: 100%;
        font-size: 18px;
    }

    .roomlogolist{
        width: 100%;
    }
}