.fontReg{
    font-family: "MontserratReg";
    color:black;
}

.fontSB{
    font-family: "MontserratSB";
    color:black;
}

.shadowimg{
    box-shadow: 13px 13px 14px#DCDCDC;
}

.Aboutus{
    padding:80px 20px 100px 30px;
}

.Aboutus p{
    margin-right: 16px;
    font-size: 18px;
    text-align: justify;
    line-height: 38px;
}

/*CSS for Gallery*/
.Homegallery{
    background-color: #967A50;
    padding-left: 30px;
    color:white;
}

.galleryleft{
    padding: 90px 0px 80px 0px;
}

.galleryleft h1{
    color: white;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.galleryleft p{
    font-size: 18px;
    letter-spacing: 0.045em;
    text-align: justify;
    line-height: 35px;
    margin: 18px 70px 0px 0px;
}

/*Service Part*/
.serviceleft{
    padding-top: 30px;
    background: rgba(196, 196, 196, 0.2);
}

.serviceleft h1{
    letter-spacing: 0.05em;
}

.serviceleft p{
    font-size: 18px;
    letter-spacing: 0.045em;
    line-height: 37px;
    text-align: justify;
    margin: 30px 60px 0px 30px;
}

.serviceright{
    padding-top: 35px;
}

.qualitybox{
    margin: 0px 15px 20px 25px;
    border: 2px solid #967A50;
    align-items: center;
}

.qualitybox img{
    padding-top: 15px;
}

.qualitybox h3{
    text-align: center;
}

.qualitybox p{
    margin:5px 10px 10px 10px;
    text-align: justify;
}

/*CSS to make homepage responsive*/
@media screen and (max-width: 600px) {
    .Aboutus{
        padding: 30px 10px 30px 15px;
    }

    .Aboutus Button{
        align-items: center;
    }

    .aboutusimage{
        padding-top: 30px;
    }

    .Homegallery{
        padding: 30px 0px 15px 0px;
    }

    .galleryleft{
        padding: 0px 0px 20px 0px;
    }

    .galleryleft p{
        margin: 18px 5px 0px 5px;
    }

    .galleryleft h1{
        margin: 0px 5px 0px 5px;
    }

    .serviceleft p{
        margin: 20px 10px 0px 15px;
    }

    .qualitybox{
        margin: 0px 10px 20px 10px;
        border: 2px solid #967A50;
        align-items: center;
    }

    .qualitybox p{
        margin:5px 0px;
        text-align: justify;
    }
}