.contactwrapper{
    padding-top: 40px;
}

.g-mapswrapper{
    padding-top: 30px;
}

/*CSS for Contact Us left Part*/
.contactleft Form{
    background-color: #fdf1e4;
    font-size: 15px;
    font-weight: 600;
    padding: 25px 25px 35px 20px;
}

.contactleft h2{
    margin-bottom: 20px;
}

.contactleft Button{
    background-color: #805f3a;
    color: white;
    font-size: 18px;
    padding: 8px 15px;
}

/*CSS for Contact Us Right part */

.contactright h2{
    text-transform: uppercase;
    letter-spacing: 0.02em;
}

.contactright p{
    line-height: 42px;
    letter-spacing: 0.015em;
    font-size: 15.5px;
    text-align: justify;
}

.contactdetail {
    padding-top:13px;
}

@media screen and (max-width: 600px) {
    .contactleft h2{
        padding:10px 0px 15px 0px ;
    }

    .contactleft Form{
        background-color: #fdf1e4;
        font-size: 15px;
        font-weight: 600;
        padding: 15px 15px 30px 15px;
    }

    .contactright{
        padding-top: 25px;
    }

    .contactdetail{
        padding: 15px 15px 0px 15px;
    }

    .contactright h2{
        text-align: center;
    }

    .contactright hr{
        margin: 0px auto !important;    
    }
}