.ButtonToTop {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: none;
  position: fixed;
  width: 50px;
  height: 50px;
  right: 10px;
  bottom: 40px;
  font-size: 33px;
  z-index: 1;
  cursor: pointer;
  color: black;
}

.ButtonToTop:hover{
  background-color:#e0e0e0;
}