.footerContainer{
    background-color: #967A50;
    color:white;
    position: absolute;
    width: 100%;
    padding-top: 40px;
}

.footerleft img{
    padding: 0px 0px 25px 30px;
}

.footermiddle p{
    color: white;
    margin-top: 10px;
    margin-left: 50px;
    line-height: 38px;
    letter-spacing: 0.05em;
}

.footermiddle h4{
    letter-spacing: 0.04em;
    color:white;
}

.footerright img{
    margin-top: 5px;
    margin-left: 3px;
}

.footerright h4{
    letter-spacing: 0.04em;
    color:white;
}

.footerright img{
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .footerContainer{
        padding-top: 25px;
    }

    .footerleft{
        text-align: center !important;
    }

    .footerleft img{
        padding: 0px 0px 25px 0px;
    }
}