.MainHeader{
    background-color: #967A50;
}

.Headerlogo{
    padding-left: 30px;
}

.Headerlogo img{
    width: 100%;
    height: auto;
}
.Headerlist{
    padding: 4px 25px 4px 10px;
}

.Headerlist Button{
    background:#599bece1;
    border:none;
}

.nav-link{
    font-size: 17px;
    margin-right: 20px;
    letter-spacing: 0.05em;
}

.nav-link:active{
    color:white;
    opacity: 100%;
}

.Modal{
    border:none;
}
.modalContent{
    background: transparent !important;
    border: none !important;
    color:white;
}

.modal-90w{
    max-height: none !important;
    max-width: none !important;
}

@media screen and (max-width: 600px) {
    .Headerlogo{
        padding-left: 15px;
    }
}