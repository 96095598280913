.facsHeader{
    background-color: #967A50;
    color:white;
    padding: 90px 0px 100px 50px;
}

.facsHeader h1{
    font-size: 50px;
    text-transform: uppercase;
    letter-spacing: 0.12em;
}

.facsbuttwrapper{
    padding: 20px 0px;
}

.facsbuttwrapper Button{
    font-size: 32px;
    font-weight: 500;
    color:#10255A;
}

.facsbuttwrapper Button:hover{
    font-size: 35px;
    font-weight: 600;
    color: #10255A;
}

.RestoButton{
    border-left: 3px solid #967A50;
    border-right: 3px solid#967A50;
}

.facsDetail{
    background-color: #fff9f2;
    padding: 50px 100px 50px 100px;
}

.facsDetail h1{
    letter-spacing: 0.06em;
}
.facsDetail p{
    width: 60%;
    padding-top:20px;
    margin: auto;
    font-size: 20px;
    line-height: 37px;
}

.facsImage{
    padding: 20px 0px;
}

@media screen and (max-width: 600px) {
    .facsHeader{
        color:white;
        padding: 90px 0px 100px 15px;
    }

    .facsHeader h1{
        font-size: 40px;
    }

    .facsDetail{
        padding: 30px 10px 50px 10px;
    }
        
    .facsDetail p{
        text-align: justify;
        width: 100%;
        margin: 0;
    }

    .RestoButton{
        border-left: none;
        border-right: none;
    }
}

